<template>
  <!-- Popup -->
  <Popup ref="popup" />

  <section
    class="text-center container col-lg-8 col-md-9 col-sm-10 mx-auto mt-2 mb-4"
  >
    <h1 class="animate-characters">The Kimon NFT Collection on Dogechain</h1>

    <h3 class="text-muted">
      Kimon is a set of 10,000 stunning art pieces with unique traits and an
      attention to detail that is rarely seen anywhere on the blockchain.
    </h3>

    <h2 class="mt-4">Collection Highlights</h2>
    <h4 class="text-muted">🔥 13 Special 1 of 1s 🔥</h4>
    <h4 class="text-muted">🔥 Plus 28 Base Characters 🔥</h4>
    <h4 class="text-muted">🔥 With 388 Traits 🔥</h4>
    <h3>A massive amount of utility coupled with beautiful artwork.</h3>

    <div class="col-xxl-4 mx-auto mt-4 mb-4">
      <img
        src="/images/kimon.gif"
        class="img-fluid rounded"
        alt="Kimon NFTs Preview"
      />
    </div>

    <h2 class="mt-4">New Discounted Minting Phase!</h2>
    <h3>Demon Mint</h3>
    <h4 class="text-muted">Friday, 9th of September at 6 pm UTC</h4>
    <h4 class="text-muted">2,000 NFTs for 200 wDOGE per NFT</h4>
    <h3 class="mt-4">Vampire Mint</h3>
    <h4 class="text-muted">Saturday, 10th of September at 6 pm UTC</h4>
    <h4 class="text-muted">8,000 NFTs available for 220 wDOGE per NFT</h4>
    <h3 class="mt-4">Dracula Mint</h3>
    <h4 class="text-muted">Saturday, 24th of September at 9 am UTC</h4>
    <h4 class="text-muted">All unminted NFTs for 75 wDOGE per NFT!</h4>
    <h4 class="text-muted">
      Holders at the snapshot date (23rd of September, 3:30 pm UTC) can mint 2
      new NFTs for 1 wDOGE per NFT!
    </h4>
    <h4 class="text-muted">
      Minting will be paused once 8,000 minted NFTs are reached!
    </h4>
    <h4 class="text-muted">
      Minting will resume once the Graveyard feature is released!
    </h4>

    <h2 class="mt-4">Learn More About Kimon</h2>
    <h4 class="text-muted">
      <a target="_blank" href="t.me/KimonNFT">🔥 Join our Telegram group 🔥</a>
    </h4>
    <h4 class="text-muted">
      <a target="_blank" href="https://nft-dojo.com/"
        >🔥 Visit the Kimon website 🔥</a
      >
    </h4>

    <h4 class="text-muted">
      After minting some Kimon NFTs, you can stake them
      <router-link to="/Kimon">here</router-link>
      to earn a passive income stream of NFTD tokens. Just select "Staking View"
      in the view selector.
    </h4>
    <h4 class="text-muted">
      In "Leveling View" you can permanently upgrade the staking power of your
      NFTs by using the non-tradable EXP token. To get some EXP tokens, please
      visit the <router-link to="/Kimon">EXP Token Shop</router-link>.
    </h4>

    <hr class="mt-4" />

    <h1 class="mt-4">Minting</h1>
    <template v-if="nfts['minted'] < nfts['maxMintable']">
      <h2>
        For {{ stakersDiscountedPriceE15 / 10 ** 3 }} {{ ETH }} you can mint 1
        NFT.
      </h2>

      <h2 v-if="stakersDiscountedPriceE15 < ethPerNftE15">
        Your personal discount has been taken into account. The regular mint
        price is {{ ethPerNftE15 / 10 ** 3 }} {{ ETH }}
      </h2>

      <h4 class="lead text-muted mx-auto my-3">
        <span
          >You can mint up to {{ nfts["maxPerTx"] }} NFTs per transaction.
        </span>
        <span>The NFTs are minted in a non-sequential, randomised order.</span>
      </h4>

      <h2 class="mx-auto my-4" id="nftsMinted">
        <span
          >{{ nfts["minted"] }} of {{ nfts["maxMintable"] }} NFTs have been
          minted.</span
        >
        <a href="#nftsMinted"
          ><i title="Reload numbers" @click="load()" class="ri-refresh-fill"></i
        ></a>
      </h2>

      <h1 v-if="mintPaused && walletAddress !== ''">
        Minting is currently paused!
      </h1>

      <template v-if="!mintPaused">
        <h4 class="lead text-muted mx-auto my-3">
          <span>Please select how many Kimon NFTs you want to mint.</span>
        </h4>

        <div class="mb-3 mx-auto">
          <div class="input-group mx-auto">
            <div class="input-group-prepend mx-auto">
              <button
                v-for="_nfts in mintSettingsArr"
                :key="_nfts"
                class="btn btn-outline-secondary"
                type="button"
                @click="setNftsToMint(_nfts)"
              >
                {{ _nfts }}
              </button>
            </div>
          </div>
        </div>

        <div class="mb-3 mx-auto">
          <div class="input-group mx-auto">
            <div class="input-group-prepend mx-auto">
              <button
                class="btn btn-outline-secondary"
                :disabled="nfts['toMint'] >= nfts['maxPerTx']"
                @click="changeNftsToMint(1)"
              >
                +
              </button>

              <input
                id="tokensToBuy"
                type="number"
                min="1"
                :max="nfts['maxPerTx']"
                class="form-control mx-auto"
                @change="changeNftsToMint(0)"
                v-model="nfts['toMint']"
              />

              <button
                class="btn btn-outline-secondary"
                :disabled="nfts['toMint'] <= 1"
                @click="changeNftsToMint(-1)"
              >
                -
              </button>
            </div>
          </div>
        </div>

        <span class="row-lg-12">
          <span class="column">
            <h4 v-if="nfts['toMint'] > 1">
              Mint {{ nfts["toMint"] }} Kimon NFTs for:
            </h4>
            <h4 v-else>Mint 1 Kimon NFT for:</h4>
            <div class="col-lg-6 col-md-8 mx-auto">
              <button
                id="buyButtonEth"
                type="button"
                class="btn btn-outline-secondary my-2 btn-large"
                :disabled="mintPaused || stakersDiscountedPriceE15 <= 0"
                @click="mintNFTsWithEth"
              >
                {{
                  Math.round(nfts["toMint"] * stakersDiscountedPriceE15) /
                  10 ** 3
                }}
                {{ ETH }}
              </button>
            </div>
          </span>
        </span>
      </template>
    </template>
    <template v-else>
      <h2 class="mt-4">
        All Kimon NFTs have been minted. Visit
        <a
          :href="nftMarketPlace + 'collection/' + mintContractAddress"
          target="_blank"
          >Oasis</a
        >
        to check if there are some for sale!
      </h2>
    </template>

    <br />

    <template v-if="!mintPaused">
      <h3 class="mt-4">
        <span
          >Your Balance:
          {{ Math.round(stakersEthBalanceE0 / 10 ** 15) / 10 ** 3 }}
          {{ ETH }}
        </span>
      </h3>

      <h3>You hold {{ nfts["owned"] }} Kimon NFTs in your wallet.</h3>

      <br />
    </template>

    <h6 class="text-muted mt-2">
      <span
        >The gas limit is set to {{ gasLimit }} to ensure a successful mint.
        Unused gas is automatically returned to your wallet. If you lower the
        gas limit, you risk having failed transactions. If a transaction fails,
        the gas fee is lost.</span
      >
    </h6>

    <h6 class="mx-auto text-muted">
      <span>Note that transactions cannot be undone.</span>
    </h6>
  </section>
</template>

<script>
import { ethers } from "ethers";
import KimonNFT from "../../artifacts/contracts/KimonNFT.sol/KimonNFT.json";

import Popup from "./Popup.vue";

export default {
  components: { Popup },

  data() {
    return {
      provider: null,
      signer: null,

      ETH: window.tokenName["eth"],
      nftMarketPlace: window.nftMarketPlace,

      walletAddress: "",
      ethPerNftE15: 75000, // 75 wDoge
      stakersDiscountedPriceE15: 75000, // set the same as ethPerNftE15 in the beginning
      stakersEthBalanceE0: 0,

      mintContract: null,
      mintContractAddress: window.address["kimon"],
      mintPaused: true,

      nfts: {
        toMint: 10,
        minPerTx: 1,
        maxPerTx: 40,
        minted: 0,
        preminted: 70, // displays 0 unless more than this number has been minted
        owned: 0,
        maxMintable: 2000,
        maxSupply: 10000,
      },

      gasLimit: 220000,

      mintSettingsArr: [],

      // stakersRewardTokenBalance: 0,

      // rewardTokenPerNft: 0,
      // flexUsdPerNft: 0,

      // contracts: null,
    };
  },
  methods: {
    showPopup(_title, _text, _timeOut) {
      this.$refs.popup.show(_title, _text, _timeOut);
    },

    async handleTx(_msg, _fx) {
      this.showPopup(_msg, "Please confirm the transaction", 0);

      try {
        let tx = await _fx;
        this.showPopup(_msg, "Waiting for transaction to finish", 0);
        await tx.wait();
        this.showPopup(_msg, "was successful", 1000);
      } catch {
        this.showPopup(_msg, "finished", 1000);
      }
    },

    getEthBalance: function () {
      this.provider.getBalance(this.walletAddress).then((balance) => {
        this.stakersEthBalanceE0 = balance;
      });
    },

    setNftsToMint: function (_amount) {
      this.nfts["toMint"] = Math.floor(_amount);
      if (this.nfts["toMint"] < this.nfts["minPerTx"]) {
        this.nfts["toMint"] = this.nfts["minPerTx"];
      } else if (this.nfts["toMint"] > this.nfts["maxPerTx"]) {
        this.nfts["toMint"] = this.nfts["maxPerTx"];
      }
    },

    changeNftsToMint: function (_changeAmount) {
      this.nfts["toMint"] = Math.floor(this.nfts["toMint"]) + _changeAmount;
      if (this.nfts["toMint"] < this.nfts["minPerTx"]) {
        this.nfts["toMint"] = this.nfts["minPerTx"];
      } else if (this.nfts["toMint"] > this.nfts["maxPerTx"]) {
        this.nfts["toMint"] = this.nfts["maxPerTx"];
      }
    },

    mintNFTsWithEth: async function () {
      await this.load(); // get most recent values

      const _nftsToMint = this.nfts["toMint"];

      // check if there are enough NFTs left to mint as the user wants
      let _nftsLeft = this.nfts["maxMintable"] - this.nfts["minted"];
      if (_nftsToMint > _nftsLeft) {
        this.showPopup(
          `There are only ${_nftsLeft} NFTs available for minting!`,
          "",
          3000
        );
        this.nfts["maxPerTx"] = _nftsLeft;
        return false;
      }

      // check if the user has enough funds
      let _totalCostEthE15 = this.stakersDiscountedPriceE15 * _nftsToMint;
      let _totalGasLimit = Math.min(
        window.maxGasPerTx,
        this.gasLimit +
          (this.gasLimit - 20000) * (_nftsToMint - 1) -
          10000 * Math.floor(_nftsToMint / 7) -
          15000 * Math.floor(_nftsToMint / 10)
      );

      console.log("totalCostEthE15: ", _totalCostEthE15);
      console.log("_totalGasLimit: ", _totalGasLimit);
      console.log(
        "_totalGasCostE15: ",
        (_totalGasLimit * window.minGasCostE0) / 10 ** 15
      );
      console.log(
        "stakersEthBalanceE15: ",
        this.stakersEthBalanceE0 / 10 ** 15
      );

      let _costInclGasE15 =
        _totalCostEthE15 + (_totalGasLimit * window.minGasCostE0) / 10 ** 15;

      if (this.stakersEthBalanceE0 / 10 ** 15 < _costInclGasE15) {
        this.showPopup(
          `You have ${
            Math.round(this.stakersEthBalanceE0 / 10 ** 14) / 10 ** 4
          } ${this.ETH} in your wallet.`,
          `You can mint up to ${Math.floor(
            this.stakersEthBalanceE0 /
              10 ** 15 /
              (this.stakersDiscountedPriceE15 +
                (this.gasLimit * window.minGasCostE0) / 10 ** 15)
          )} NFTs with that.`,
          5000
        );
        return false;
      }

      // mint NFTs
      const _msg = `Minting ${_nftsToMint} NFTs`;

      await this.handleTx(
        _msg,
        this.mintContract.mint(_nftsToMint, {
          value: ethers.utils.parseUnits(_totalCostEthE15.toString(), 15),
          gasLimit: _totalGasLimit.toString(),
          gasPrice: window.minGasCostE0,
        })
      );

      const _nftsOwned = this.nfts["owned"];
      await this.load();
      const _nftsMinted = this.nfts["owned"] - _nftsOwned;
      if (_nftsMinted === _nftsToMint) {
        this.showPopup(_msg, "was successful", 3000);
      } else if (_nftsMinted <= 0) {
        this.showPopup(
          _msg,
          "was not successful. Please try again later!",
          3000
        );
      } else {
        this.showPopup(_msg, `${_nftsMinted} were minted`, 3000);
      }
    },

    load: async function () {
      this.signer.getAddress().then((_myAddress) => {
        this.walletAddress = _myAddress;
        this.getEthBalance();
        // this.getRewardTokenBalance();
        // this.getFlexUsdBalance();
        this.mintContract.balanceOf(_myAddress).then((_nftsOwned) => {
          this.nfts["owned"] = _nftsOwned;
        });
        this.mintContract.getInfo(_myAddress).then((_result) => {
          this.mintPaused = _result[0] === 0 ? false : true;
          this.nfts["minted"] =
            _result[1] > this.nfts["preminted"] ? _result[1] : 0;
          this.nfts["maxMintable"] = _result[2];
          this.nfts["maxSupply"] = _result[3];
          this.nfts["maxPerTx"] = _result[4];
          let _arr = [];

          if (_result[4] >= 50) {
            _arr = [1, 5, 10, 15, 20, 30, 40, _result[4]];
          } else if (_result[4] >= 40) {
            _arr = [1, 5, 10, 15, 20, 25, 30, 40];
          } else if (_result[4] >= 30) {
            _arr = [1, 5, 10, 15, 20, 25, 30];
          } else if (_result[4] >= 20) {
            _arr = [1, 5, 10, 12, 15, 18, 20];
          } else if (_result[4] >= 10) {
            _arr = [1, 3, 5, 7, 9, 10];
          } else if (_result[4] >= 5) {
            _arr = [1, 2, 3, 4, 5];
          }
          this.mintSettingsArr = _arr;
          this.ethPerNftE15 = _result[5];
          let _discountCode = _result[6];

          if (_discountCode > 0) {
            // 1 - n equivalent to 1 - n discounts left
            let _discountsLeft = _discountCode;
            if (this.nfts["maxPerTx"] > _discountsLeft) {
              this.nfts["maxPerTx"] = _discountsLeft;
              this.nfts["toMint"] = _discountsLeft;
            }
            this.stakersDiscountedPriceE15 = 1000;
          }

          // if (_discountCode > 999) {
          //   // 1000 or more
          //   this.stakersDiscountedPriceE15 = 1000;
          // } else if (_discountCode > 9) {
          //   // 10 - 509 equivalent to 1 - 500 discounts left
          //   let _discountsLeft = _discountCode - 9;
          //   if (this.nfts['maxPerTx'] > _discountsLeft) {
          //     this.nfts['maxPerTx'] = _discountsLeft;
          //     this.nfts['toMint'] = _discountsLeft;
          //   }
          //   this.stakersDiscountedPriceE15 = 60000;
          // } else if (_discountCode > 4) {
          //   // 5 - 9
          //   this.stakersDiscountedPriceE15 =
          //     this.ethPerNftE15 - (_discountCode - 5) * 10000 - 22000;
          // } else {
          //   // 0 - 4
          //   this.stakersDiscountedPriceE15 =
          //     this.ethPerNftE15 - _discountCode * 10000;
          // }
        });
      });
    },
  },

  mounted: function () {
    this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");

    this.provider
      .send("eth_requestAccounts", [])
      .then(() => {
        this.signer = this.provider.getSigner();

        this.mintContract = new ethers.Contract(
          window.address["kimon"],
          KimonNFT.abi,
          this.signer
        );
      })
      .then(() => {
        this.load();
      });
  },
};
</script>

<style scoped>
.input-group {
  z-index: 0;
}
</style>